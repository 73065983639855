<template>
  <div style=" width: 100%; height: 100%; padding: 0 15px 15px;">
    <b-section-scroll class="g-bg">
      <!-- 头部 -->
      <div
        slot="header"
        class="header"
      >
        <el-tabs
          class="r-nav"
          v-model="activeName"
        >
          <el-tab-pane
            label="修改密码"
            name="password"
          ></el-tab-pane>
        </el-tabs>
      </div>
      <!-- 内容 -->
      <div>
        <password-view></password-view>
      </div>
    </b-section-scroll>
  </div>
</template>

<script>
import PasswordView from './components/password.vue'
export default {
  components: {
    PasswordView,
  },
  data() {
    return {
      activeName: 'password',
    }
  },
  computed: {
    userId() {
      return this.$store.getters['user/id']
    },
    deptId() {
      return this.$store.getters['user/deptId']
    },
  },
  methods: {},
}
</script>
<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f1f1f1;
}
</style>
